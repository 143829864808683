.contactusContainer{
    height: 100vh;
    width: 100%;
    background-color: #1C312C;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/realstatebackground.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-blend-mode:multiply;


}
h3{
text-align: center;}

form{

    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    flex-direction: column;
background-color: #485D58;
max-width: 600px;
border-radius: 10px;}

form input, form textarea{
    margin: 10px 10px;
    padding: 20px;
    outline: none;
    background-color: #f5f5f5;
    font-size: 16px;
}

#sub{background-color: #e9d9cf;
margin: 0px 10px 10px;
width: 150px;
border: 1px solid white;

border-radius: 30px;

}

