.CarouselContainer{
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 70vw;
}


.homediv{background-color: #1C312C;
    background-image: url(../assets/realstatebackground.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-blend-mode:multiply;}


