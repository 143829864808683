.scontainer{background-color: #485D58;

    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.scontainer > p{color: white;
text-align: center;
}
.Aboutpage{background-color: #1C312C;
    background-image: url(../assets/realstatebackground.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    background-blend-mode:multiply;}